import React, { lazy } from "react";
import { Link } from "react-router-dom";
import Button2 from "../../common/Button2";
import FullWidthContainer from "../../common/FullWidthContainer";
import {HashLink} from "react-router-hash-link";

import { SectionLineIMG } from "../../components/SectionLine";

const Container = lazy(() => import("../../common/Container"));
const ScrollToTop = lazy(() => import("../../common/ScrollToTop"));
const IntroNeu = lazy(() => import("../../components/IntroNeu"));
const SectionLine = lazy(() => import("../../components/SectionLine"));
const Slideshow = lazy(() => import("../../components/Slideshow"));
const AnnouncementTeaser = lazy(() => import("../../components/AnnouncementTeaser"));
const Downloads = lazy(() => import("../../components/Downloads"));
const UeberUns = lazy(() => import("../../components/UeberUns"))
const Motivation = lazy(() => import("../../components/Motivation"))
const Unterstuetzung = lazy(() => import("../../components/Unterstuetzung"))
const SocialMedia = lazy(() => import("../../components/SocialMedia"))

const Home = () => {
  return (
    <>

      <AnnouncementTeaser/>
      <IntroNeu/>

      {/* Scroll To Top Pfeil unten Rechts */}
      <ScrollToTop />



      <SectionLine how = "center"/>
      <Motivation/>

      <SectionLine how = "center"/>
      <Unterstuetzung/>

      {/* Slideshow Wahlplakate */}
      <SectionLine how = "left"/>
      <UeberUns/>


      <SectionLine how = "right"/>
      <Slideshow
        images={[
          'img/wahlplakate/web-abgabe.png',
          'img/wahlplakate/web-auto.png',
          'img/wahlplakate/web-friedländer.png',
          'img/wahlplakate/web-kind.png',
      ]}
      />

      {/* Downloads */}
      <div id="downloads"/>
      <SectionLine how = "right"/>
      {/* <Downloads/> */}
      <SocialMedia/>
      <SectionLine how = "center"/>

    </>
  );
};

export default Home;
